<!-- <template>
  <div>
    <div class="chatting-interactions">
      <div class="display-chat-sent-pt3">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Ticket Log</h4>
          </div>
        </div>
        <a href="#">
          <span class="material-icons reload"> cached </span>
        </a>
      </div>      
      <div v-for="(row, key) in ticketLogs.rows" :key="key">
        <li>{{ row.name }}{{ row.logged }}</li>
      </div>
    </div>
  </div>
</template> -->
<template>
  <div class="chatting-interactions">
    <div class="display-chat-sent-pt3">
      <div class="chat-message-box">
        <div class="chat-who-img"></div>
        <div class="chat-msg-content">
          <h4>Ticket Logs</h4>
        </div>
      </div>
      <a href="#">
        <span style="margin-right: 20px" class="material-icons reload">
          cached
        </span>
      </a>
    </div>

    <!-- <div
                    style="overflow: scroll; height: 390px"
                    class="interactions-chatting-box"
                  > -->
    <div
      class="interactions-chatting-box"
      v-for="(row, key) in ticketLogs.rows"
      :key="key"
    >
      <div v-if =" $auth.user().id===row.user_id" class="display-chat-sent-pt4">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>{{ row.name }}</h4>
            <!-- {{ $auth.user().id }} -->
            <!-- {{ row.user_id }}             -->
            <p class="date-time">{{ new Date(row.updated_at).toLocaleString("en-US") }}</p>           
            <p>{{ row.logged }}</p>
          </div>
        </div>
      </div>
      <div v-else class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>{{ row.name }}</h4>
            <p class="date-time">{{ new Date(row.updated_at).toLocaleString("en-US") }}</p>
            <p>
              {{ row.logged }}
            </p>
          </div>
        </div>
      </div>

      <!-- <div class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Ordered 3 items</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>
            <p>
              Fact that a reader will be distracted and can copy the path with
              the correct direction.
            </p>
          </div>
        </div>
      </div> -->

      <!-- <div class="display-chat-sent-pt4">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Hari Maharjan</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>
            <p>
              It is a long established fact that a reader will be distracted
            </p>
          </div>
        </div>
      </div> -->

      <!-- <div class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Article viewed</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>
            <p>
              Fact that a reader will be distracted and can copy the path with
              the correct direction.
            </p>
          </div>
        </div>
      </div> -->

      <!-- <div class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Article viewed</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>
            <p>
              Established fact that a reader will be distracted and can copy the
              path with the correct direction.
            </p>
          </div>
        </div>
      </div> -->

      <!-- <div class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Article viewed</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>

            <p>This email is for the priority stopping to the directions.</p>
          </div>
        </div>
      </div> -->
      <!-- <div class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Article viewed</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>

            <p>This email is for the priority stopping to the directions.</p>
          </div>
        </div>
      </div> -->
      <!-- <div class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Article viewed</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>

            <p>This email is for the priority stopping to the directions.</p>
          </div>
        </div>
      </div>
            <div class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Article viewed</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>

            <p>This email is for the priority stopping to the directions.</p>
          </div>
        </div>
      </div>
            <div class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Article viewed</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>

            <p>This email is for the priority stopping to the directions.</p>
          </div>
        </div>
      </div>
            <div class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Article viewed</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>

            <p>This email is for the priority stopping to the directions.</p>
          </div>
        </div>
      </div>
            <div class="display-chat-sent-pt5">
        <div class="chat-message-box">
          <div class="chat-who-img"></div>
          <div class="chat-msg-content">
            <h4>Article viewed</h4>
            <p class="date-time">Feb 08. 9:05 AM</p>

            <p>This email is for the priority stopping to the directions.</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <!-- <div class="chatting-interactions">
    <div class="display-chat-sent-pt3">
      <div class="chat-message-box">
        <div class="chat-who-img">
          <img
            src="https://toppng.com/uploads/preview/business-man-11530978835jxznfsrwvz.png"
            alt=""
          />
        </div>
        <div class="chat-msg-content">
          <h4>Ticket Log</h4>
        </div>
      </div>
      <div class="chat-icon-box">
        <a href="">
          <span class="material-icons"> cached </span>
        </a>
      </div>
    </div>
    

    <div class="interactions-chatting-box">
      <ul class="list-style">
        <li class="ticket-item">
          <div class="timeline-item">

          </div>

          <div class="timeline-content">
            <h1>Chat with Riley</h1>
          </div>
          </li>
      </ul>
      
    </div>
    <div
      style="overflow: scroll; height: 400px"
      class="interactions-chatting-box"
    >
      <div class="display-chat-sent-pt4" v-for="(row, key) in ticketLogs.rows" :key="key">
        <li>
          {{ row.name }}{{ row.logged }} <br />
          {{ row.updated_at }}
        </li>
      </div>
    </div>
  </div> -->
</template>

<script>
import { RestService, SERVICELIST } from "@/library/RestService";
const $restService = RestService(SERVICELIST.SUPPORT_TICKET);

export default {
	props: {
		ticketId: {
			required: true,
		},
	},
	data() {
		return {
			ticketLogs: [],
		};
	},
	watch: {
		ticketId: function (value) {
			this.fetchTicketLog(value);
		},
	},

	mounted() {
		// console.log(this.ticketId,'......');
	},
	methods: {
		fetchTicketLog(ticketId) {
			$restService
				.get(`/api/ticket-log?ticket_id=${ticketId}`)
				.then(({ data }) => {
					// console.log(data);
					this.ticketLogs = data;
				});
		},
	},
};
</script>


